/* @pageが想定の動作をしないので一度コメントアウト */
/* @page {
  size: A5 landscape;
  margin: 0mm;
} */

.page {
  page-break-after: always;
}

.page:last-child {
  page-break-after: auto;
}

.break {
  display: block;
  page-break-before: always;
}
